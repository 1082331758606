import React from 'react';
import PropTypes from 'prop-types';
import marked from 'marked';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

/**
 * Markdown view
 * @param markdown
 * @param options  https://marked.js.org/#/USING_ADVANCED.md#options
 * @param className
 */
export function MarkdownView({ markdown, options, className, fragmentRef }) {
  return (
    <ContentFragment ref={fragmentRef} classes={className}>
      {marked(markdown, options)}
    </ContentFragment>
  );
}

MarkdownView.propTypes = {
  markdown: PropTypes.string.isRequired,
  options: PropTypes.shape({}),
  className: PropTypes.string,
  fragmentRef: PropTypes.shape({ current: PropTypes.object }),
};

MarkdownView.defaultProps = {
  options: {},
  className: '',
  fragmentRef: null,
};
